import React, { useCallback, useEffect, useState } from 'react'
import { notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from '../../../../api/getApi'
import { getCitiesAction } from '../../../../store/search/reducer'
import { selectBasketProducts } from '../../../../store/basket/selectors'
import {
  selectDocLink,
  selectGoogleSheetsLink,
  selectImportOrGenerateLoading,
  selectLinkToTable,
  selectOrderValue,
  selectPdfLink,
  selectRubGoogleSheetsLink,
} from '../../../../store/generate-table/selectors'
import {
  setBillPdfLinkAction,
  setImportTablePdfLinkAction,
  setDocLinkAction,
  setGoogleSheetsLinkAction,
  setLinkToTableAction,
  setPdfLinkAction,
  setRubGoogleSheetsLinkAction,
  setSpecificationDocLinkAction,
  setSpecificationPdfLinkAction,
  setImportOrGenerateLoading,
} from '../../../../store/generate-table/reducer'
import {
  BillData,
  GenerateBillAsyncResponse,
  GenerateProposalAsyncResponse,
  GenerateSpecificationAsyncResponse,
  ImportTableData,
  ProposalData,
  ProposalWarning,
  SpecificationData,
} from '../../../../api/proposal/proposal'
import {
  GenerateProposalSpreadsheetAsyncResponse,
  RegenerateProposalSpreadsheetAsyncResponse,
} from '../../../../api/spreadsheet/spreadsheet'
import * as Sentry from '@sentry/react'
import AttachService from '../../../../api/attach/attach'
import { TableGeneration } from './components/TableGeneration/TableGeneration'
import { ProposalActions } from './components/ProposalActions/ProposalActions'
import { getIdFromGoogleSpreadsheetUrl } from './ProposalGeneration.utils'
import { BILL_LINK_KEY, DOC_LINK_KEY, GOOGLE_SHEETS_LINK_KEY, PDF_LINK_KEY, RUB_GOOGLE_SHEETS_LINK_KEY, SPECIFICATION_DOC_LINK_KEY, SPECIFICATION_PDF_LINK_KEY } from '../../../../constants/localStorage'
import {useBasket} from '../basket/basket.hook'
import {ApiError} from '../../../../api/apiError/apiError'

export const ProposalGeneration: React.FC = () => {
  const dispatch = useDispatch()
  const {initState} = useBasket()

  const pdfLink = useSelector(selectPdfLink)
  const docLink = useSelector(selectDocLink)
  const orderValue = useSelector(selectOrderValue)
  const basketProducts = useSelector(selectBasketProducts)
  const linkToTable = useSelector(selectLinkToTable)
  const importOrGenerateLoading = useSelector(selectImportOrGenerateLoading)
  const googleSheetsLink: string = useSelector(selectGoogleSheetsLink)
  const rubGoogleSheetsLink: string = useSelector(selectRubGoogleSheetsLink)

  const [isLoading, setLoading] = useState<boolean>(false)
  const isOrderValueEmpty = !orderValue?.trim()?.length
  const isButtonDisabled: boolean = isLoading || isOrderValueEmpty
  const isAttachButtonDisabled: boolean =
    isLoading || isOrderValueEmpty || !docLink || !pdfLink || !linkToTable || !googleSheetsLink || !rubGoogleSheetsLink

  useEffect(() => {
    dispatch(getCitiesAction())
  }, [dispatch])

  const [warnings, setWarnings] = useState<Array<string>>([])

  const generateProposal = useCallback((spreadsheetLink: string) => {
    setLoading(true)
    setWarnings([])

    getApi()
      .proposal
      .generateProposalAsync({
        id: orderValue,
        spreadsheetId: getIdFromGoogleSpreadsheetUrl(spreadsheetLink),
      })
      .then((response: GenerateProposalAsyncResponse) => response.job_id)
      .then((jobId: string) =>
        getApi()
          .proposal
          .getJobResult<ProposalData>({
            jobId,
          }),
      )
      .then((proposalData: ProposalData) => {
        const {
          Pdf: pdf,
          Doc: doc,
          Spreadsheet: spreadsheet,
          SpreadsheetRub: rubSpreadsheet,
          Warnings: responseWarnings,
        } = proposalData

        dispatch(setGoogleSheetsLinkAction(spreadsheet))
        dispatch(setRubGoogleSheetsLinkAction(rubSpreadsheet))
        dispatch(setPdfLinkAction(pdf))
        dispatch(setDocLinkAction(doc))
        localStorage.setItem(GOOGLE_SHEETS_LINK_KEY, spreadsheet)
        localStorage.setItem(RUB_GOOGLE_SHEETS_LINK_KEY, rubSpreadsheet)
        localStorage.setItem(PDF_LINK_KEY, pdf)
        localStorage.setItem(DOC_LINK_KEY, doc)

        const newWarnings: Array<string> =
          (responseWarnings ?? [])
            .map(({ Text: text }: ProposalWarning) => text)
        setWarnings(newWarnings)
        if (newWarnings.length) {
          warningHandler('Коммерческое предложение сгенерировано, но не готово к отправке.')
          return
        }

        notificationHandler('Коммерческое предложение успешно сгенерировано!')
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при генерации коммерческого предложения',
          description: error.getRuText?.() || error.message,
          duration: 10,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, orderValue])

  const generateSpreadsheetUrl = useCallback(async (): Promise<string> => {
    dispatch(setImportOrGenerateLoading(true))
    const { job_id: jobId }: GenerateProposalSpreadsheetAsyncResponse =
      await getApi()
        .spreadsheet
        .generateProposalSpreadsheetAsync({
          id: orderValue,
          items: basketProducts.map(product => ({
            ...product,
            price: product.price || 0,
            count: product.count || 0,
          })),
        })

    return getApi()
      .proposal
      .getJobResult<string>({
        jobId,
      })
  }, [basketProducts, orderValue])

  const regenerateSpreadsheetUrl = useCallback(async (): Promise<string> => {
    dispatch(setImportOrGenerateLoading(true))
    const { job_id: jobId }: RegenerateProposalSpreadsheetAsyncResponse =
      await getApi()
        .spreadsheet
        .regenerateProposalSpreadsheetAsync({
          sourceSpreadsheetId: getIdFromGoogleSpreadsheetUrl(linkToTable),
        })

    return getApi()
      .proposal
      .getJobResult<string>({
        jobId,
      })
  }, [linkToTable])

  const generateSpreadsheet = useCallback(async (isWithSavingData: boolean) => {
    setLoading(true)
    setWarnings([])

    try {
      const spreadsheetUrl: string = isWithSavingData
        ? await regenerateSpreadsheetUrl()
        : await generateSpreadsheetUrl()
      dispatch(setLinkToTableAction(spreadsheetUrl))

      if (basketProducts.length > 0) {
        generateProposal(spreadsheetUrl)
      }
      else {
        setLoading(false)
      }

      localStorage.setItem('linkToTable', spreadsheetUrl)

      if (basketProducts.length > 0) {
        notificationHandler('Таблица успешно сгенерирована! Через несколько секунд будет сгенерировано коммерческое предложение.')
        return
      }

      notificationHandler('Таблица успешно сгенерирована! Добавьте товары вручную и нажмите "Перегенировать КП".')
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при генерации таблицы',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)
    }
    finally {
      setLoading(false)
      dispatch(setImportOrGenerateLoading(false))
    }
  }, [basketProducts.length, dispatch, generateProposal, generateSpreadsheetUrl, regenerateSpreadsheetUrl])

  const generateBill = useCallback(() => {
    setLoading(true)
    setWarnings([])

    getApi()
      .proposal
      .generateBillAsync({
        dealId: +orderValue,
      })
      .then((response: GenerateBillAsyncResponse) => response.job_id)
      .then((jobId: string) =>
        getApi()
          .proposal
          .getJobResult<BillData>({
            jobId,
          }),
      )
      .then((billData: BillData) => {
        const billPdfUrl: string = billData?.PdfUrl ?? null
        dispatch(setBillPdfLinkAction(billPdfUrl))
        localStorage.setItem(BILL_LINK_KEY, billPdfUrl)
        notificationHandler('Счет успешно сгенерирован')
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при генерации счета',
          description: error.getRuText?.() || error.message,
          duration: 10,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, orderValue])

  const importTable = (parentDealId: number) => {
    setLoading(true)
    setWarnings([])
    dispatch(setImportTablePdfLinkAction(''))
    dispatch(setImportOrGenerateLoading(true))

    getApi()
      .proposal
      .importTableAsync({
        parentDealId: parentDealId,
        dealId: +orderValue,
      })
      .then((response: GenerateSpecificationAsyncResponse) => response.job_id)
      .then((jobId: string) =>
        getApi()
          .proposal
          .getJobResult<ImportTableData>({
            jobId,
          }),
      )
      .then((importData: ImportTableData) => {
        const importTablePdfUrl: string = importData?.data ?? null
        dispatch(setImportTablePdfLinkAction(importTablePdfUrl))
        localStorage.setItem('linkToTable', importTablePdfUrl)
        notificationHandler('Таблица успешно импортирована')
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при попытке импортировать таблицу',
          description: error.getRuText?.() || error.message,
          duration: 10,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
      .then(() => {
        setTimeout(() => initState(), 700)
      })
      .finally(() => {
        dispatch(setImportOrGenerateLoading(false))
      })
  }

  const generateSpecification = useCallback(() => {
    setLoading(true)
    setWarnings([])

    getApi()
      .proposal
      .generateSpecificationAsync({
        spreadsheetId: getIdFromGoogleSpreadsheetUrl(linkToTable),
        dealId: +orderValue,
      })
      .then((response: GenerateSpecificationAsyncResponse) => response.job_id)
      .then((jobId: string) =>
        getApi()
          .proposal
          .getJobResult<SpecificationData>({
            jobId,
          }),
      )
      .then((specificationData: SpecificationData) => {
        const specificationPdfUrl: string = specificationData?.Pdf ?? null
        const specificationDocUrl: string = specificationData?.Doc ?? null
        dispatch(setSpecificationPdfLinkAction(specificationPdfUrl))
        dispatch(setSpecificationDocLinkAction(specificationDocUrl))
        localStorage.setItem(SPECIFICATION_PDF_LINK_KEY, specificationPdfUrl)
        localStorage.setItem(SPECIFICATION_DOC_LINK_KEY, specificationDocUrl)
        notificationHandler('Спецификация успешно сгенерирована')
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при генерации спецификации',
          description: error.getRuText?.() || error.message,
          duration: 10,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, orderValue, linkToTable])

  const attachProposal = useCallback(() => {
    setLoading(true)
    setWarnings([])

    AttachService
      .attach({
        id: orderValue,
        proposalDocUrl: docLink,
        proposalPdfUrl: pdfLink,
        spreadsheetUrl: linkToTable,
        proposalSpreadsheetUrl: googleSheetsLink,
        proposalSpreadsheetRubUrl: rubGoogleSheetsLink,
      })
      .then(() => {
        notificationHandler('Коммерческое предложение успешно прикреплено к сделке!')
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при прикреплении коммерческого предложения к сделке',
          description: error.getRuText?.() || error.message,
          duration: 10,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [docLink, linkToTable, orderValue, pdfLink, googleSheetsLink, rubGoogleSheetsLink])

  const notificationHandler = (notificationText: string) => {
    notification.success({
      message: `Успешно`,
      description: `${notificationText}`,
    })
  }

  const warningHandler = (notificationText: string) => {
    notification.warning({
      message: `Предупреждение`,
      description: `${notificationText}`,
      duration: 5,
    })
  }

  return (
    <>
      <TableGeneration
        isLoading={isLoading}
        isButtonDisabled={isButtonDisabled}
        generateSpreadsheet={generateSpreadsheet}
        importTable={importTable}
      />

      {(linkToTable || importOrGenerateLoading) && (
        <ProposalActions
          isLoading={isLoading}
          isGenerationButtonDisabled={isButtonDisabled}
          isAttachButtonDisabled={isAttachButtonDisabled}
          warnings={warnings}
          generateProposal={() => generateProposal(linkToTable)}
          generateBill={generateBill}
          generateSpecification={generateSpecification}
          attachProposal={attachProposal}
        />
      )}
    </>
  )
}
