import { Tag } from 'antd'
// import IntlMessage from '../components/util-components/IntlMessage'
import moment from 'moment'
import { Link, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { GetOrderResponse, OrderItemResponse, OrderPositionResponse, OrderResponse, TwinUserItemResponse } from '../../api/orders/orders'
import { FormattedMessage } from 'react-intl'
import {Theme as ReactTableLibraryTheme} from '@table-library/react-table-library/types/theme'

export const enum OrderStatusEnum {
  Proposal = 'proposal',
  Agreement = 'agreement',
  InProgress = 'in_progress',
  Received = 'received',
  Delivered = 'delivered',
  Success = 'success',
  Cancel = 'cancel',
}

export const ORDER_STATUSES: Array<OrderStatusEnum> = [
  OrderStatusEnum.Proposal,
  OrderStatusEnum.Agreement,
  OrderStatusEnum.InProgress,
  OrderStatusEnum.Received,
  OrderStatusEnum.Delivered,
  OrderStatusEnum.Success,
  OrderStatusEnum.Cancel,
]

export const enum OrderStatusGroupEnum {
  Proposals = 'proposals',
  Active = 'active',
  Archive = 'archive',
}

export const enum PaymentConditionCodeEnum {
  PartPrepaid = 'part_prepaid',
}

export interface Order {
  id: number;
  name: string;
  status: OrderStatusEnum;
  statusGroup: OrderStatusGroupEnum;
  responsibleUserName: string;
  dealBudget: number;
  paymentConditionCode: PaymentConditionCodeEnum;
  paymentConditionDescription: string;
  paymentValue: number;
  paymentDeadlineDate: number;
  billId: string;
  billPdfUrl: string;
  proposalId: string;
  proposalPdfUrl: string;
  creationDate: number;
  supplyDeadlineDate: number;
  deliveryCity: string;
  items: Array<OrderItem>;
  supplyDate: number;
}

export const enum OrderItemStatusEnum {
  SentToClient = 'sent_to_client',
  Obo = 'obo',
  CustomsCleared = 'customs_cleared',
  OnBorder = 'on_border',
  SentToRu = 'sent_to_ru',
  SentToTransit = 'sent_to_transit',
  ArrivedToHub = 'arrived_to_hub',
  SentToHub = 'sent_to_hub',
  OnTsw = 'on_tsw',
  StoreDelay = 'store_delay',
  Ordered = 'ordered',
  Procurement = 'procurement',
}

export const ORDER_ITEM_STATUSES: Array<OrderItemStatusEnum> = [
  OrderItemStatusEnum.SentToClient,
  OrderItemStatusEnum.Obo,
  OrderItemStatusEnum.CustomsCleared,
  OrderItemStatusEnum.OnBorder,
  OrderItemStatusEnum.SentToRu,
  OrderItemStatusEnum.SentToTransit,
  OrderItemStatusEnum.ArrivedToHub,
  OrderItemStatusEnum.SentToHub,
  OrderItemStatusEnum.OnTsw,
  OrderItemStatusEnum.StoreDelay,
  OrderItemStatusEnum.Ordered,
  OrderItemStatusEnum.Procurement,
]

export interface OrderItem {
  id: number;
  name: string;
  quantity: number;
  totalPrice: number;
  status: OrderItemStatusEnum;
  statusDescription: string;
  expectedDeliveryDate: number;
  deliveryCondition: string;
  lastStatusDate: number;
  comment: string;
}

export function getWaitingForPaymentDays(supplyUnixDate: number): number {
  if (!supplyUnixDate) {
    return 0
  }

  return moment()
    .diff(
      moment.unix(supplyUnixDate),
      'days',
    )
}

// 2021-08-30 => 1630281600
export function stringDateToUnixTimestamp(stringData: string): number {
  if (!stringData?.length) {
    return 0
  }

  return new Date(stringData).getTime() / 1000
}

export function unixTimestampToFormattedDate(timestamp: number): string {
  if (!timestamp) {
    return ''
  }

  return moment.unix(timestamp).format('DD.MM.YYYY')
}

export function unixTimestampToTimestamp(unixTimestamp: number): number {
  return unixTimestamp * 1000
}

function mapOrderItemResponseToOrderItem(response: OrderItemResponse): OrderItem {
  return {
    deliveryCondition: '', // TODO
    expectedDeliveryDate: stringDateToUnixTimestamp(response?.expected_delivery_date),
    id: response?.id,
    name: response?.name,
    quantity: response?.count,
    status: response?.status as OrderItemStatusEnum,
    statusDescription: response?.status_desc,
    totalPrice: response?.price,
    lastStatusDate: stringDateToUnixTimestamp(response?.last_status_date),
    comment: '', // TODO
  }
}

export function mapGetOrderResponseToOrder(response: GetOrderResponse): Order | null {
  if (!response?.order) {
    return null
  }

  const price: number = parseInt(response?.order?.price, 10) || 0

  return {
    billId: response?.order?.bill_number,
    billPdfUrl: response?.bill_pdf_url,
    creationDate: response?.order?.created_at,
    dealBudget: price,
    deliveryCity: response?.order?.delivery_city,
    id: response?.order?.id,
    items: response?.items?.map(mapOrderItemResponseToOrderItem),
    name: response?.order?.name,
    paymentConditionCode: response?.order?.pay_condition_code as PaymentConditionCodeEnum,
    paymentConditionDescription: response?.order?.pay_condition_desc,
    paymentDeadlineDate: response?.order?.payment_deadline,
    paymentValue: price - (response?.order?.money_received || 0),
    proposalId: `${response?.order?.id}`,
    proposalPdfUrl: response?.proposal_pdf_url,
    responsibleUserName: response?.order?.responsible_user_name,
    status: response?.order?.status as OrderStatusEnum,
    statusGroup: response?.order?.status_group as OrderStatusGroupEnum,
    supplyDeadlineDate: response?.order?.supply_deadline,
    supplyDate: stringDateToUnixTimestamp(response?.order?.supply_date),
  }
}

export function mapOrderResponseToOrder(response: OrderResponse): Order {
  const price: number = parseInt(response?.price, 10) || 0

  return {
    billId: response?.bill_number,
    billPdfUrl: '',
    creationDate: response?.created_at,
    dealBudget: price,
    deliveryCity: response?.delivery_city,
    id: response?.id,
    items: [],
    name: response?.name,
    paymentConditionCode: response?.pay_condition_code as PaymentConditionCodeEnum,
    paymentConditionDescription: response?.pay_condition_desc,
    paymentDeadlineDate: response?.payment_deadline,
    paymentValue: price - (response?.money_received || 0),
    proposalId: `${response?.id}`,
    proposalPdfUrl: '',
    responsibleUserName: response?.responsible_user_name,
    status: response?.status as OrderStatusEnum,
    statusGroup: response?.status_group as OrderStatusGroupEnum,
    supplyDeadlineDate: response?.supply_deadline,
    supplyDate: stringDateToUnixTimestamp(response?.supply_date),
  }
}

export const ORDER_ITEM_STATUS_TO_PRIORITY: Record<OrderItemStatusEnum, number> = {
  [OrderItemStatusEnum.SentToClient]: 11,
  [OrderItemStatusEnum.Obo]: 10,
  [OrderItemStatusEnum.CustomsCleared]: 9,
  [OrderItemStatusEnum.OnBorder]: 8,
  [OrderItemStatusEnum.SentToRu]: 7,
  [OrderItemStatusEnum.SentToTransit]: 6,
  [OrderItemStatusEnum.ArrivedToHub]: 5,
  [OrderItemStatusEnum.SentToHub]: 4,
  [OrderItemStatusEnum.OnTsw]: 3,
  [OrderItemStatusEnum.StoreDelay]: 2,
  [OrderItemStatusEnum.Ordered]: 1,
  [OrderItemStatusEnum.Procurement]: 0,
}

export const ORDER_STATUS_TO_PRIORITY: Record<OrderStatusEnum, number> = {
  [OrderStatusEnum.Proposal]: 0,
  [OrderStatusEnum.Agreement]: 1,
  [OrderStatusEnum.InProgress]: 2,
  [OrderStatusEnum.Delivered]: 3,
  [OrderStatusEnum.Received]: 4,
  [OrderStatusEnum.Success]: 5,
  [OrderStatusEnum.Cancel]: 6,
}

type BadgeConfig = {
  message: string;
  color: string;
}

const DEFAULT_BADGE_CONFIG: BadgeConfig = {
  message: '',
  color: 'default',
}

export const ORDER_STATUS_TO_BADGE_CONFIG: Record<OrderStatusEnum, BadgeConfig> = {
  [OrderStatusEnum.Proposal]: {
    message: 'Proposal formation',
    color: 'geekblue',
  },
  [OrderStatusEnum.Agreement]: {
    message: 'Proposal in agreement',
    color: 'gold',
  },
  [OrderStatusEnum.InProgress]: {
    message: 'In progress',
    color: 'geekblue',
  },
  [OrderStatusEnum.Received]: {
    message: 'Completely in stock',
    color: 'orange',
  },
  [OrderStatusEnum.Delivered]: {
    message: 'Completely delivered',
    color: 'cyan',
  },
  [OrderStatusEnum.Success]: {
    message: 'Successfully finished',
    color: 'rgba(33,181,115,.6)',
  },
  [OrderStatusEnum.Cancel]: {
    message: 'Cancelled',
    color: 'red',
  },
}

export const ORDER_ITEM_STATUS_TO_BADGE_CONFIG: Record<OrderItemStatusEnum, BadgeConfig> = {
  [OrderItemStatusEnum.SentToClient]: {
    message: 'Направлено клиенту',
    color: 'success',
  },
  [OrderItemStatusEnum.Obo]: {
    message: 'Прибыло на склад в Москве',
    color: 'green',
  },
  [OrderItemStatusEnum.CustomsCleared]: {
    message: 'Таможня пройдена',
    color: 'lime',
  },
  [OrderItemStatusEnum.OnBorder]: {
    message: 'Прибыло на таможню',
    color: 'gold',
  },
  [OrderItemStatusEnum.SentToRu]: {
    message: 'Отправлено в РФ',
    color: 'orange',
  },
  [OrderItemStatusEnum.SentToTransit]: {
    message: 'Отправлено на транзитный узел',
    color: 'volcano',
  },
  [OrderItemStatusEnum.ArrivedToHub]: {
    message: 'Пришло на хаб',
    color: 'red',
  },
  [OrderItemStatusEnum.SentToHub]: {
    message: 'Отправлено на хаб',
    color: 'magenta',
  },
  [OrderItemStatusEnum.OnTsw]: {
    message: 'Прибыло на временный склад',
    color: 'purple',
  },
  [OrderItemStatusEnum.StoreDelay]: {
    message: 'Задержка от поставщика',
    color: 'geekblue',
  },
  [OrderItemStatusEnum.Ordered]: {
    message: 'Заказано у поставщика',
    color: 'blue',
  },
  [OrderItemStatusEnum.Procurement]: {
    message: 'Передано в закупку',
    color: 'blue',
  },
}

export function mapOrderStatusToBadge(status: OrderStatusEnum): React.ReactNode {
  const { color } = ORDER_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color || !status) {
    return null
  }

  return (
    <Tag
      color={color}
      style={{lineHeight: '20px'}}
    >
      <span className='font-weight-semibold'>
        <FormattedMessage id={'orders.status.' + status}/>
      </span>
    </Tag>
  )
}

export function mapOrderItemStatusToMessage(
  status: OrderItemStatusEnum,
  statusDescription: string,
  lastStatusDate: number,
): string {
  const { message, color } = ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color) {
    return ''
  }

  const statusMessage: string = statusDescription || message
  if (lastStatusDate === 0) {
    return statusMessage
  }

  return `${unixTimestampToFormattedDate(lastStatusDate)} ${statusMessage}`
}

export function mapOrderItemStatusToBadge(
  status: OrderItemStatusEnum,
  statusDescription: string,
  lastStatusDate: number,
): React.ReactNode {
  const { message, color } = ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status] ?? DEFAULT_BADGE_CONFIG
  if (!color) {
    return null
  }

  return (
    <Tag color={color}>
      <span className='font-weight-semibold'>
        {lastStatusDate !== 0 && (
          <span>{unixTimestampToFormattedDate(lastStatusDate)}{' '}</span>
        )}
        {statusDescription || message}
      </span>
    </Tag>
  )
}

export const enum ThemeColorEnum {
  Light = 'light',
  Dark = 'dark',
}

export function getCustomTableTheme(theme: ThemeColorEnum): ReactTableLibraryTheme {
  return {
    Row: `
      &:hover {
        background-color: ${theme === ThemeColorEnum.Light ? '#e7f5ff' : '#444'};
      }
    `,
  }
}

export const enum ViewTypeEnum {
  Grid = 'grid',
  AntDesignTable = 'ant',
  ErpTable = 'erp',
}

export function useViewType(defaultViewType: ViewTypeEnum): ViewTypeEnum {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])

  return query.get('viewType') as ViewTypeEnum ?? defaultViewType
}

function getOrderUrl(orderId: number): string {
  return `/order/${orderId}`
}

type LinkToOrderProps = {
  orderId: number;
}

export const LinkToOrder = (props: LinkToOrderProps) => {
  const {orderId} = props
  if (!orderId) {
    return null
  }

  return (
    <Link to={getOrderUrl(orderId)}>
      {orderId}
    </Link>
  )
}

// 12345678 => '12 345 678'
export function formatPrice(price: number): string {
  return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const ORDER_STATUS_GROUP_TO_ORDER_STATUSES: Record<OrderStatusGroupEnum, Array<OrderStatusEnum>> = {
  [OrderStatusGroupEnum.Proposals]: [
    OrderStatusEnum.Proposal,
    OrderStatusEnum.Agreement,
  ],
  [OrderStatusGroupEnum.Active]: [
    OrderStatusEnum.InProgress,
    OrderStatusEnum.Received,
    OrderStatusEnum.Delivered,
  ],
  [OrderStatusGroupEnum.Archive]: [
    OrderStatusEnum.Success,
    OrderStatusEnum.Cancel,
  ],
}

export function getSetsIntersection<T>(sets: Array<Set<T>>): Set<T> {
  if (!sets.length) {
    return new Set<T>()
  }

  const firstSet = sets[0]
  if (sets.length === 1) {
    return firstSet
  }

  const restSets: Array<Set<T>> = sets.slice(1)

  return [...firstSet.values()].reduce((intersection, value) => {
    const isInAllRestSets: boolean = restSets.every(set => set.has(value))
    if (!isInAllRestSets) {
      return intersection
    }

    return intersection.add(value)
  }, new Set<T>())
}

export const EMPTY_VALUE_ID = 'EMPTY_VALUE_ID'

export interface OrderPosition {
  id: number;
  clientName: string;
  orderId: number;
  name: string;
  deadline: number;
  expectedDeliveryDate: number;
  status: OrderItemStatusEnum;
  statusDescription: string;
  lastStatusDate: number;
  causeOfWaiting: string;
  comment: string;
  procurementComment: string;
  sdComment: string;
}

export function mapOrderPositionResponseToOrderPosition(response: OrderPositionResponse): OrderPosition {
  return {
    id: response?.id,
    clientName: response?.client_name,
    orderId: response?.order_id,
    name: response?.name,
    deadline: response?.deadline,
    expectedDeliveryDate: response?.expected_delivery_date,
    status: response?.status as OrderItemStatusEnum,
    statusDescription: response?.status_desc,
    lastStatusDate: stringDateToUnixTimestamp(response?.last_status_date),
    causeOfWaiting: response?.cause_of_waiting,
    comment: response?.comment,
    procurementComment: '',
    sdComment: '',
  }
}

export function mapTwinUserItemResponseToOrderPosition(response: TwinUserItemResponse): OrderPosition {
  return {
    id: response?.id,
    clientName: response?.client_name,
    orderId: response?.deal_id,
    name: response?.name,
    deadline: stringDateToUnixTimestamp(response?.deadline_date || ''),
    expectedDeliveryDate: stringDateToUnixTimestamp(response?.expected_delivery_date || ''),
    status: response?.status as OrderItemStatusEnum,
    statusDescription: response?.status_description,
    lastStatusDate: stringDateToUnixTimestamp(response?.last_status_date),
    causeOfWaiting: response?.delay_reason,
    comment: response?.sales_comments,
    procurementComment: response?.prt_comments,
    sdComment: response?.sd_comments,
  }
}
