import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getApi } from '../../../../api/getApi'
import { selectBasketProducts } from '../../../../store/basket/selectors'
import { ProductBasketType } from '../../../../store/basket/types'
import {
  setAddToBtnDisable,
  setEditedItemIndex,
  setInitialStateAction,
  setOpenHandleEditor,
  setProductsListAction,
} from '../../../../store/basket/reducer'
import {
  setBillPdfLinkAction,
  setDisabledOrderFormAction,
  setDocLinkAction,
  setGoogleSheetsLinkAction,
  setLinkToTableAction,
  setOrderValueAction,
  setPdfLinkAction,
  setRubGoogleSheetsLinkAction,
} from '../../../../store/generate-table/reducer'
import { notification } from 'antd'
import { ProductType } from '../../../../store/search/types'
import { availabilityApi, GetProductAvailabilityResponse } from '../../../../api/availability'
import { GetProposalResponse } from '../../../../api/proposal/proposal'
import * as Sentry from '@sentry/react'
import { BILL_LINK_KEY, DOC_LINK_KEY, GOOGLE_SHEETS_LINK_KEY, PDF_LINK_KEY, RUB_GOOGLE_SHEETS_LINK_KEY } from '../../../../constants/localStorage'
import { ApiError } from '../../../../api/apiError/apiError'
import { isNumeric } from '../../../../utils/number'
import {ApiErrorCodeEnum} from '../../../../api/apiError/apiErrorCode.enum'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const useBasket = () => {
  const dispatch = useDispatch()
  const basketProducts = useSelector(selectBasketProducts)
  const [loadingBasket, setLoadingBasket] = useState<boolean>(true)
  const [isVisibleLoader, _setIsVisibleLoader] = useState<boolean>(true)
  const query = useQuery()
  const history = useHistory()

  const setIsVisibleLoader = () => {
    _setIsVisibleLoader(isVisibleLoader)
  }

  const setInitialBasketStatus = () => {
    setLoadingBasket(false)
    setIsVisibleLoader()
    const number = query.get('number') ? query.get('number') : ''
    if (number !== localStorage.getItem('orderNumber')) {
      dispatch(setInitialStateAction([]))
      localStorage.setItem('basketAppscience', '')
      localStorage.setItem('orderNumber', number!)
      localStorage.removeItem('linkToTable')
      localStorage.removeItem(GOOGLE_SHEETS_LINK_KEY)
      localStorage.removeItem(RUB_GOOGLE_SHEETS_LINK_KEY)
      localStorage.removeItem(PDF_LINK_KEY)
      localStorage.removeItem(DOC_LINK_KEY)
      localStorage.removeItem(BILL_LINK_KEY)
    }
    const data = localStorage.getItem('basketAppscience')
    if (data) {
      const info = JSON.parse(localStorage.getItem('basketAppscience')!)
      dispatch(setInitialStateAction(info))
    }
    else {
      dispatch(setInitialStateAction([]))
    }
  }

  const initTableInfoState = () => {
    const urlNumber = query.get('number')
    if (urlNumber) {
      dispatch(setDisabledOrderFormAction(true))
      dispatch(setOrderValueAction(urlNumber))
    }
    const _linkToTable = localStorage.getItem('linkToTable')
    const _googleSheetsLink = localStorage.getItem(GOOGLE_SHEETS_LINK_KEY)
    const rubGoogleSheetsLink = localStorage.getItem(RUB_GOOGLE_SHEETS_LINK_KEY)
    const _pdfLink = localStorage.getItem(PDF_LINK_KEY)
    const _docLink = localStorage.getItem(DOC_LINK_KEY)
    const billLink = localStorage.getItem(BILL_LINK_KEY)
    _linkToTable && dispatch(setLinkToTableAction(_linkToTable))
    _googleSheetsLink && dispatch(setGoogleSheetsLinkAction(_googleSheetsLink))
    rubGoogleSheetsLink && dispatch(setRubGoogleSheetsLinkAction(rubGoogleSheetsLink))
    _pdfLink && dispatch(setPdfLinkAction(_pdfLink))
    _docLink && dispatch(setDocLinkAction(_docLink))
    billLink && dispatch(setBillPdfLinkAction(billLink))
  }

  //Init Generate And Basket State
  const initState = () => {
    const urlNumber = query.get('number')

    if (!urlNumber) {
      setInitialBasketStatus()
      initTableInfoState()
      return
    }

    if (!isNumeric(urlNumber)) {
      setDataInStore({
        bill_pdf_url: '',
        items: [],
        proposal_doc_url: '',
        proposal_pdf_url: '',
        spreadsheet_url: '',
      })
      dispatch(setOrderValueAction(''))
      history.push('/')
      notification.warning({
        message: 'Номер сделки должен состоять из цифр (0-9)',
        duration: 5,
      })
      return
    }

    dispatch(setDisabledOrderFormAction(true))
    setIsVisibleLoader()

    getApi()
      .proposal
      .getProposalData({
        id: urlNumber,
      })
      .then((response: GetProposalResponse) => {
        dispatch(setOrderValueAction(urlNumber))
        setDataInStore(response)
      })
      .catch((error: ApiError) => {
        setDataInStore({
          bill_pdf_url: '',
          items: [],
          proposal_doc_url: '',
          proposal_pdf_url: '',
          spreadsheet_url: '',
        })
        dispatch(setOrderValueAction(''))
        history.push('/')
        if (error instanceof ApiError && error?.getCode() === ApiErrorCodeEnum.get_amo_deal) {
          notification.error({
            message: `Ошибка при получении данных КП`,
            description: `Сделка с номером ${urlNumber} не найдена`,
            duration: 5,
          })
          return
        }

        notification.error({
          message: 'Ошибка при получении данных КП',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })

        Sentry.captureException(error)
      })
      .finally(() => {
        setLoadingBasket(false)
      })
  }

  const setDataInStore = (data: GetProposalResponse) => {
    const {
      items,
      proposal_pdf_url,
      proposal_doc_url,
      spreadsheet_url,
      bill_pdf_url,
    } = data

    localStorage.setItem('basketAppscience', JSON.stringify(items ? items : []))
    dispatch(setProductsListAction(items))
    dispatch(setDocLinkAction(proposal_doc_url || ''))
    dispatch(setPdfLinkAction(proposal_pdf_url || ''))
    dispatch(setLinkToTableAction(spreadsheet_url || ''))
    dispatch(setBillPdfLinkAction(bill_pdf_url || ''))
  }

  const onCountHandler = (value: number, itemIndex: number, availability_type?: string) => {
    if (value === 0) return

    const data: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)
    if (value === -1) {
      data.splice(itemIndex, 1)
      localStorage.setItem('basketAppscience', JSON.stringify(data))
      dispatch(setProductsListAction([...data]))
    }
    else {
      data[itemIndex].count = value
      localStorage.setItem('basketAppscience', JSON.stringify(data))
      dispatch(setProductsListAction([...data]))
    }
    (value > 0) && (availability_type === 'ON-DEMAND') && availabilityRequestHandler(data[itemIndex])
  }


  const availabilityRequestHandler = (object: ProductType) => {
    dispatch(setAddToBtnDisable(true))
    const id = object.id
    const _dataArray: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)
    const count = _dataArray.filter(item => item.id === id)[0].count

    availabilityApi
      .getProductAvailability(id, count)
      .then(({ available }: GetProductAvailabilityResponse) => {
        _dataArray.forEach((_item, index) => {
          if (_item.id === id) {
            _dataArray[index].availability_flag = available
          }
        })
        localStorage.setItem('basketAppscience', JSON.stringify(_dataArray))
        dispatch(setProductsListAction([..._dataArray]))
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при получении данных о доступности',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setAddToBtnDisable(false))
      })
  }

  const onStartEditHandler = (index: number) => {
    dispatch(setOpenHandleEditor(true))
    dispatch(setEditedItemIndex(index))
  }

  return {
    //props
    basketProducts,
    loadingBasket,
    isVisibleLoader,

    //methods
    setInitialBasketStatus,
    onCountHandler,
    initTableInfoState,
    initState,
    setIsVisibleLoader,
    onStartEditHandler,
  }
}
