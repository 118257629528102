import React, { useEffect } from 'react'
import { Col, Row, Tabs } from 'antd'
import { SearchContent } from './content/search/index'
import { BasketContent } from './content/basket/index'
import { ProposalGeneration } from './content/ProposalGeneration/index'
import { setEmailAction } from '../../store/auth/reducer'
import { useDispatch } from 'react-redux'
import { HandleInputBasketContent } from './content/handle-input-basket/index'
import { SideHandleEditorContent } from './content/side-handle-editor/index'
import StickyBox from 'react-sticky-box'
import { APP_SCIENCE_EMAIL_KEY } from '../../constants/localStorage'

const { TabPane } = Tabs

export const ProposalView: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const email = localStorage.getItem(APP_SCIENCE_EMAIL_KEY) ?? ''
    dispatch(setEmailAction(email))
  }, [dispatch])

  return (
    <Row className='pt-3'>
      <Col
        span={24}
        xl={12}
        className='pr-2 pl-2'
      >
        <Tabs
          type='card'
          defaultActiveKey='1'
        >
          <TabPane
            tab='Поиск по каталогу'
            key='1'
          >
            <SearchContent />
          </TabPane>
          <TabPane
            tab='Ручной ввод'
            key='2'
          >
            <HandleInputBasketContent />
          </TabPane>
        </Tabs>
      </Col>
      <Col
        span={24}
        xl={12}
        className='pr-2 pl-2 mt-4'
      >
        <StickyBox
          offsetTop={20}
          offsetBottom={20}
        >
          <BasketContent />
          <ProposalGeneration />
        </StickyBox>
      </Col>
      <SideHandleEditorContent />
    </Row>
  )
}
