import {
  OrderPositionsStageLocalStorage,
} from './types'
import {useMemo} from 'react'
import { notificationError } from '../../views/OrdersView/notifications'

export interface ProposalStorage {
	orderPositionsStage: OrderPositionsStageLocalStorage,
}

type StorageKeys<T extends keyof ProposalStorage> = keyof ProposalStorage[T]
type KeyValue<Storage extends keyof ProposalStorage, Key extends keyof ProposalStorage[Storage]> = ProposalStorage[Storage][Key]

export function useLocalStorage<STORAGE extends keyof ProposalStorage>(storage: STORAGE) {
  return useMemo(() => getLocalStorageCallbacks(storage), [storage])
}

const generateKey = (storage: keyof ProposalStorage) => `proposal_${storage}`

export function getLocalStorageCallbacks<STORAGE extends keyof ProposalStorage>(storage: STORAGE) {
  function set<KEY extends StorageKeys<STORAGE>>(key: KEY, value: KeyValue<STORAGE, KEY>) {
    setValueToStorage(storage, key, value)
  }
  function get<KEY extends StorageKeys<STORAGE>>(key: KEY) {
    return getStorageValue(storage, key)
  }

  return {
    setValueToStorage: set,
    getStorageValue: get,
    getStorageObject: () => getStorageObject(storage),
  }
}

export function getStorageObject<STORAGE extends keyof ProposalStorage>(storage: STORAGE): ProposalStorage[STORAGE] | null {
  const rawValue = localStorage.getItem(generateKey(storage))
  if (rawValue === null) {
    return null
  }
  return parseJSON<ProposalStorage[STORAGE]>(rawValue)
}

function getStorageValue<STORAGE extends keyof ProposalStorage, KEY extends StorageKeys<STORAGE>>(storage: STORAGE, key: KEY): KeyValue<STORAGE, KEY> | null {
  const parsedData = getStorageObject(storage)
  return parsedData ? parsedData[key] : null
}

function setValueToStorage<STORAGE extends keyof ProposalStorage, KEY extends StorageKeys<STORAGE>>(storage: STORAGE, key: KEY, value: KeyValue<STORAGE, KEY>) {
  const currentState = getStorageObject(storage)
  localStorage.setItem(generateKey(storage), JSON.stringify({
    ...currentState,
    [key]: value,
  }))
}

function parseJSON<EXPECTED = undefined>(json: string): EXPECTED|null {
  try {
    return JSON.parse(json) as EXPECTED
  }
  catch (e) {
    // defaultError({
    //   errorObj: e,
    // })
    notificationError('Persistance storage error')
    return null
  }
}