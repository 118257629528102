import { Alert, Button, Card, Col, Image, List, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import googleSheetsIconSrc from '../../../../../../assets/img/google-sheets-icon.png'
import pdfIconSrc from '../../../../../../assets/svg/pdf-icon.svg'
import googleDocsIconSrc from '../../../../../../assets/svg/google-docs-icon.svg'
import { useSelector } from 'react-redux'
import { selectBillPdfLink, selectDocLink, selectGoogleSheetsLink, selectPdfLink, selectRubGoogleSheetsLink, selectSpecificationDocLink, selectSpecificationPdfLink } from '../../../../../../store/generate-table/selectors'
import { OpenLinkIcon, ProposalActionIcon } from '../ProposalActionsIcon/ProposalActionsIcon'


type ProposalActionsProps = {
  isLoading: boolean;
  isGenerationButtonDisabled: boolean;
  isAttachButtonDisabled: boolean;
  warnings: Array<string>;
  generateProposal: () => void;
  generateBill: () => void;
  generateSpecification: () => void;
  attachProposal: () => void;
}

export function ProposalActions({
  isLoading,
  isGenerationButtonDisabled,
  isAttachButtonDisabled,
  warnings,
  generateBill,
  generateProposal,
  generateSpecification,
  attachProposal,
}: ProposalActionsProps) {
  const googleSheetsLink: string = useSelector(selectGoogleSheetsLink)
  const rubGoogleSheetsLink: string = useSelector(selectRubGoogleSheetsLink)
  const pdfLink = useSelector(selectPdfLink)
  const docLink = useSelector(selectDocLink)
  const billPdfLink = useSelector(selectBillPdfLink)
  const specificationPdfLink = useSelector(selectSpecificationPdfLink)
  const specificationDocLink = useSelector(selectSpecificationDocLink)

  return (
    <Card>
      <Row>
        {isLoading && (
          <Col span={24}>
            <div className='d-flex justify-content-center'>
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </div>
          </Col>
        )}
        {!isLoading && (
          <>
            <Col
              span='6'
            >
              <Button
                type='primary'
                block
                className='mb-4'
                disabled={isGenerationButtonDisabled}
                onClick={generateProposal}
              >
                Сгенерировать КП
              </Button>
            </Col>
            <Col
              span='5'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!googleSheetsLink}
              >
                <a
                  href={googleSheetsLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <OpenLinkIcon />
                  <span
                    className='mr-1 ml-1'
                  >
                    (с USD)
                  </span>
                  <ProposalActionIcon
                    src={googleSheetsIconSrc}
                  />
                </a>
              </Button>
            </Col>
            <Col
              span='5'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!rubGoogleSheetsLink}
              >
                <a
                  href={rubGoogleSheetsLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <OpenLinkIcon />
                  <span
                    className='mr-1 ml-1'
                  >
                    (без USD)
                  </span>
                  <ProposalActionIcon
                    src={googleSheetsIconSrc}
                  />
                </a>
              </Button>
            </Col>
            <Col
              span='4'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!pdfLink}
              >
                <a
                  href={pdfLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <div className='mr-1'>
                    <OpenLinkIcon />
                  </div>
                  <ProposalActionIcon
                    src={pdfIconSrc}
                  />
                </a>
              </Button>
            </Col>
            <Col
              span='4'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!docLink}
              >
                <a
                  href={docLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <div className='mr-1'>
                    <OpenLinkIcon />
                  </div>
                  <ProposalActionIcon
                    src={googleDocsIconSrc}
                  />
                </a>
              </Button>
            </Col>
            <Col
              span='8'
            >
              <Button
                type='primary'
                block
                className='mb-4'
                disabled={isGenerationButtonDisabled}
                onClick={generateBill}
              >
                Сгенерировать Счет
              </Button>
            </Col>
            <Col
              span='4'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!billPdfLink}
              >
                <a
                  href={billPdfLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <div className='mr-1'>
                    <OpenLinkIcon />
                  </div>
                  <ProposalActionIcon
                    src={pdfIconSrc}
                  />
                </a>
              </Button>
            </Col>
            <Col
              span='12'
              className='pl-2'
            >
            </Col>
            <Col
              span='9'
            >
              <Button
                type='primary'
                block
                className='mb-4'
                disabled={isGenerationButtonDisabled}
                onClick={generateSpecification}
              >
                Сгенерировать Спецификацию
              </Button>
            </Col>
            <Col
              span='4'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!specificationPdfLink}
              >
                <a
                  href={specificationPdfLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <div className='mr-1'>
                    <OpenLinkIcon />
                  </div>
                  <ProposalActionIcon
                    src={pdfIconSrc}
                  />
                </a>
              </Button>
            </Col>
            <Col
              span='4'
              className='pl-2'
            >
              <Button
                block
                className='mb-4'
                disabled={!specificationDocLink}
              >
                <a
                  href={specificationDocLink}
                  target='_blank'
                  className='d-flex align-items-center justify-content-center'
                >
                  <div className='mr-1'>
                    <OpenLinkIcon />
                  </div>
                  <ProposalActionIcon
                    src={googleDocsIconSrc}
                  />
                </a>
              </Button>
            </Col>
            {warnings.length > 0 && (
              <Col
                span={24}
                className='mb-4'
              >
                <Alert
                  message='Есть проблемы с КП'
                  description={(
                    <List>
                      {warnings.map((warning: string) => (
                        <List.Item>
                          {warning}
                        </List.Item>
                      ))}
                    </List>
                  )}
                  type='warning'
                  showIcon
                />
              </Col>
            )}
            <Col span={24}>
              <Button
                type='primary'
                block
                disabled={isAttachButtonDisabled}
                onClick={attachProposal}
              >
                Прикрепить КП
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Card>
  )
}
