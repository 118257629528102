import apiCreator from '../apiCreator'
import { ProductBasketType } from '../../store/basket/types'
import { API_URL } from '../../constants/environment'

export type PostSpreadsheetRequest = {
  id: string;
  items: Array<ProductBasketType>;
}

export type GenerateProposalSpreadsheetAsyncRequest = PostSpreadsheetRequest
export type GenerateProposalSpreadsheetAsyncResponse = {
  job_id: string;
}

export type RegenerateProposalSpreadsheetAsyncRequest = {
  sourceSpreadsheetId: string;
}
export type RegenerateProposalSpreadsheetAsyncResponse = {
  job_id: string;
}

type SpreadsheetApiType = {
  post: (request: PostSpreadsheetRequest) => Promise<string>,
  generateProposalSpreadsheetAsync:
  (request: GenerateProposalSpreadsheetAsyncRequest)
      => Promise<GenerateProposalSpreadsheetAsyncResponse>,
  regenerateProposalSpreadsheetAsync:
    (request: RegenerateProposalSpreadsheetAsyncRequest)
      => Promise<RegenerateProposalSpreadsheetAsyncResponse>,
}

export const spreadsheet: SpreadsheetApiType = {
  post: (request: PostSpreadsheetRequest): Promise<string> =>
    apiCreator
      .post<string>(
        `${API_URL.PROPOSALS}/proposal-spreadsheet`,
        request,
      )
      .then(({ data }) => data),
  generateProposalSpreadsheetAsync: request =>
    apiCreator
      .post<GenerateProposalSpreadsheetAsyncResponse>(
        `${API_URL.PROPOSALS}/proposal-spreadsheet-async`,
        request,
      )
      .then(({ data }) => data),
  regenerateProposalSpreadsheetAsync: ({
    sourceSpreadsheetId,
  }: RegenerateProposalSpreadsheetAsyncRequest): Promise<RegenerateProposalSpreadsheetAsyncResponse> =>
    apiCreator
      .post<RegenerateProposalSpreadsheetAsyncResponse>(
        `${API_URL.PROPOSALS}/proposal-spreadsheet-async`,
        {
          source_spreadsheet_id: sourceSpreadsheetId,
          mode: 'regenerate',
        },
      )
      .then(({ data }) => data),
}
