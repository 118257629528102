import apiCreator from '../apiCreator'
import { ProductBasketType } from '../../store/basket/types'
import { API_URL } from '../../constants/environment'

export type GetProposalRequest = {
  id: string;
}
export type GetProposalResponse = {
  bill_pdf_url: string;
  items: Array<ProductBasketType>;
  proposal_doc_url: string;
  proposal_pdf_url: string;
  spreadsheet_url: string;
}

export type PostProposalRequest = {
  id: string;
  spreadsheetId: string;
}
export type PostProposalResponse = {
  doc: string;
  new_pdf: string;
  pdf: string;
  spreadsheet: string;
  warnings: Array<string>;
}

export type CreateBillResponse = {
  number: number;
  pdf_url: string;
}

export type GenerateProposalAsyncRequest = PostProposalRequest
export type GenerateProposalAsyncResponse = {
  job_id: string;
}
export type ProposalWarning = {
  Code: string;
  Text: string;
}
export type ProposalData = {
  Doc: string;
  NewPdf: string;
  Pdf: string;
  Spreadsheet: string;
  SpreadsheetRub: string;
  Warnings: Array<ProposalWarning>;
}

export type GenerateBillAsyncRequest = {
  dealId: number;
}
export type GenerateBillAsyncResponse = {
  job_id: string;
}
export type BillData = {
  Number: number;
  PdfUrl: string;
}
export type ImportTableData = {
  result: string;
  data: string;
}
export type GenerateSpecificationAsyncRequest = {
  spreadsheetId: string;
  dealId: number;
}
export type ImportTableAsyncRequest = {
  parentDealId: number;
  dealId: number;
}
export type GenerateSpecificationAsyncResponse = {
  job_id: string;
}
export type ImportTableAsyncResponse = {
  job_id: string;
}
export type SpecificationData = {
  Pdf: string;
  Doc: string;
}

export type GetJobResultRequest = {
  jobId: string;
  isPolling?: boolean;
}
export enum GetJobResultResponseResultEnum {
  Success = 'success',
  Error = 'error',
  NoData = 'no_data',
}
export type GetJobResultResponse = {
  result: GetJobResultResponseResultEnum;
  data: string;
}

type ProposalApiType = {
  post: (request: PostProposalRequest) => Promise<PostProposalResponse>,
  getProposalData: (request: GetProposalRequest) => Promise<GetProposalResponse>,
  createBill: (dealId: number) => Promise<CreateBillResponse>,
  generateProposalAsync: (request: GenerateProposalAsyncRequest) => Promise<GenerateProposalAsyncResponse>,
  generateBillAsync: (request: GenerateBillAsyncRequest) => Promise<GenerateBillAsyncResponse>,
  generateSpecificationAsync: (request: GenerateSpecificationAsyncRequest) => Promise<GenerateSpecificationAsyncResponse>,
  importTableAsync: (request: ImportTableAsyncRequest) => Promise<ImportTableAsyncResponse>,
  getJobResult: <ResultDataType>(request: GetJobResultRequest) => Promise<ResultDataType>,
}

export const proposal: ProposalApiType = {
  post: ({ id, spreadsheetId }: PostProposalRequest) =>
    apiCreator
      .post<PostProposalResponse>(
        `${API_URL.PROPOSALS}/proposal`,
        {
          id,
          spreadsheet_id: spreadsheetId,
        },
      )
      .then(({ data }) => data),

  getProposalData: ({ id }: GetProposalRequest): Promise<GetProposalResponse> =>
    apiCreator
      .get<GetProposalResponse>(
        `${API_URL.PROPOSALS}/get`,
        {
          params: {
            id,
          },
        },
      )
      .then(({ data }) => data),

  createBill: (dealId: number): Promise<CreateBillResponse> =>
    apiCreator
      .post<CreateBillResponse>(
        `${API_URL.PROPOSALS}/bill`,
        {
          deal_id: dealId,
        },
      )
      .then(({ data }) => data),

  generateProposalAsync: ({ id, spreadsheetId }: GenerateProposalAsyncRequest): Promise<GenerateProposalAsyncResponse> =>
    apiCreator
      .post<GenerateProposalAsyncResponse>(
        `${API_URL.PROPOSALS}/proposal-async`,
        {
          id,
          spreadsheet_id: spreadsheetId,
        },
      )
      .then(({ data }) => data),

  generateBillAsync: ({ dealId }: GenerateBillAsyncRequest): Promise<GenerateBillAsyncResponse> =>
    apiCreator
      .post<GenerateBillAsyncResponse>(
        `${API_URL.PROPOSALS}/bill-async`,
        {
          deal_id: dealId,
        },
      )
      .then(({ data }) => data),

  importTableAsync: ({ dealId, parentDealId }: ImportTableAsyncRequest): Promise<ImportTableAsyncResponse> =>
    apiCreator
      .get<ImportTableAsyncResponse>(
        `${API_URL.PROPOSALS}/proposal-spreadsheet-copy-async`,
        {
          params: {
            deal_id: dealId,
            parent_deal_id: parentDealId,
          },
        },
      )
      .then(({ data }) => data),

  generateSpecificationAsync: ({ dealId, spreadsheetId }: GenerateSpecificationAsyncRequest): Promise<GenerateSpecificationAsyncResponse> =>
    apiCreator
      .get<GenerateSpecificationAsyncResponse>(
        `${API_URL.PROPOSALS}/specification-async`,
        {
          params: {
            deal_id: dealId,
            spreadsheet_id: spreadsheetId,
          },
        },
      )
      .then(({ data }) => data),

  getJobResult: <ResultDataType>({ jobId, isPolling = true }: GetJobResultRequest): Promise<ResultDataType> =>
    getJobResult({ jobId, isPolling }),
}

async function getJobResult<ResultDataType>({ jobId, isPolling = true }: GetJobResultRequest): Promise<ResultDataType> {
  try {
    const axiosResponse = await apiCreator
      .get<GetJobResultResponse>(
        `${API_URL.PROPOSALS}/get-job-result`,
        {
          params: {
            id: jobId,
            polling: isPolling,
          },
        },
      )

    const responseData: GetJobResultResponse = axiosResponse.data

    if (
      axiosResponse.status === 200
      && responseData?.result === GetJobResultResponseResultEnum.Success
    ) {
      return JSON.parse(responseData?.data) as ResultDataType
    }

    if (responseData?.result === GetJobResultResponseResultEnum.NoData) {
      return await getJobResult({ jobId, isPolling })
    }

    if (responseData?.result === GetJobResultResponseResultEnum.Error) {
      throw new Error(responseData?.data)
    }

    throw new Error(`Failed long polling with status code ${axiosResponse.status}`)
  }
  catch (error) {
    return Promise.reject(error)
  }
}
