import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from '../../../../api/getApi'
import { selectAnalogs, selectProducts } from '../../../../store/search/selectors'
import { setAnalogsList, setProductsList } from '../../../../store/search/reducer'
import { ProductType } from '../../../../store/search/types'
import { setAddToBtnDisable, setProductsListAction } from '../../../../store/basket/reducer'
import { ProductBasketType } from '../../../../store/basket/types'
import { SelectProps } from 'antd/es/select'
import { caseInsensitiveCompare } from '../../../../utils/compare'
import { SearchLabel } from './search-label'
import axios from 'axios'
import { selectTheme } from '../../../../store/theme/selectors'
import {
  axiosSearchRequestData,
  axiosSuggestRequestData,
} from './search.type'
import { notificationCount, notificationSuccess } from './search.notofications'
import { isFilteredWord } from './search.utils'
import { getProductById } from '../../../../api/get-product-by-id/get-product-by-id'
import { availabilityApi, GetProductAvailabilityResponse } from '../../../../api/availability'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { GetPartnerProductsResponse, GetSuggestionsResponse, Suggestion, searchApi } from '../../../../api/search/search'
import {ApiError} from '../../../../api/apiError/apiError'

export const PAGINATION_PAGE_SIZE = 8
export const PAGINATION_ANALOGS_PAGE_SIZE = 2

export const useSearchContentHook = () => {
  //Disaptch
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  //Props
  const [searchProp, setSearchProp] = useState<string>('any')
  const [searchWord, _setSearchWord] = useState<string | number>('')
  const [vendor, setVendor] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [filteredWord, _setFilteredWord] = useState<string>('')
  const [searched, setSearched] = useState<boolean>(false)
  const [searchPaginationCurrent, setSearchPaginationCurrent] = useState<number>(1)
  const [searchPaginationTotalProductCount, setSearchPaginationTotalProductCount] = useState<number>(0)
  const [vendorsList, setVendorsList] = useState<string[]>([])
  const [searchVendorsList, setSearchVendorsList] = useState<string[]>([])
  // @ts-ignore
  const [visibleLoader, setVisibleLoader] = useState<boolean>(false)
  const [loadingSearchByWord, setLoadingSearchByWord] = useState<boolean>(true)
  const [searchOptions, setSearchOptions] = useState<SelectProps<object>['options']>([])
  const [requestSearchWord, setRequestSearchWord] = useState<string>('')
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false)
  //Analogs Props
  const [analogsCasId, setAnalogsCasId] = useState<string>('')
  const [analogsLoading, setAnalogsLoading] = useState<boolean>(false)
  const [currentShowAnalogsItems, setCurrentShowAnalogsItems] = useState<number>(10)
  const [paginationAnalogsCurrent, setPaginatioAnalogsCurrent] = useState<number>(1)
  const [totalAnalogsPagination, setTotalAnalogsPagination] = useState<number>(0)
  const [sortingProp, setSortingProp] = useState<string>('')
  const [isReverseSorting, setIsReverseSorting] = useState<boolean>(false)
  //Products
  const products = useSelector(selectProducts)
  const analogs = useSelector(selectAnalogs)

  const setSearchWord = (value: string) => {
    if (!value.trim()) {
      return
    }
    _setSearchWord(value)
    setPaginatioAnalogsCurrent(1)
  }

  const setFilteredWord = (value: string) => {
    const trimString: string = `${value}`.trim().toUpperCase()
    const _length = analogs.filter(item => isFilteredWord(item, trimString)).length
    setTotalAnalogsPagination(_length)
    setPaginatioAnalogsCurrent(1)
    _setFilteredWord(value)
  }

  const onSearchHandler = (value?: string, paginationValue?: number) => {
    axiosSearchRequestData.source.cancel()

    setDropdownVisible(false)

    axiosSearchRequestData.cancelToken = axios.CancelToken
    axiosSearchRequestData.source = axiosSuggestRequestData.cancelToken.source()

    if (!`${requestSearchWord}`.trim()) {
      return
    }

    //Revert sorting reset
    setSortingProp('')
    setIsReverseSorting(false)

    setSearchLoading(true)
    setFilteredWord('')
    setSearchPaginationCurrent(paginationValue ? paginationValue : 1)
    if (!paginationValue) {
      setPaginatioAnalogsCurrent(1)
      setTotalAnalogsPagination(0)
      dispatch(setAnalogsList([]))
    }

    searchApi
      .getPartnerProducts(
        {
          vendors: searchVendorsList,
          any: value ? value : requestSearchWord as string,
          count: PAGINATION_PAGE_SIZE,
          page: paginationValue ? paginationValue - 1 : 0,
        },
        axiosSearchRequestData.source.token,
      )
      .then(({
        items_count: itemsCount,
        partner_products,
      }: GetPartnerProductsResponse) => {
        const partnerProducts: Array<ProductType> = partner_products.slice(0, PAGINATION_PAGE_SIZE) ?? []
        setSearchPaginationTotalProductCount(itemsCount)
        dispatch(setProductsList(partnerProducts))

        const casId = partnerProducts?.[0]?.cas_id
        const id = partnerProducts?.[0]?.id

        setAnalogsCasId(casId ?? '')
        if (!paginationValue) {
          onSearchAnalogsHandler(casId, id)
        }
      })
      .catch((error: ApiError) => {
        dispatch(setProductsList([]))
        dispatch(setAnalogsList([]))
        notification.error({
          message: 'Ошибка при получении данных поисковой выдачи',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setSearchLoading(false)
        setSearched(true)
      })
  }

  const onSearchAnalogsHandler = (casId?: string, id?: string) => {
    if (!casId || casId === 'n/a') {
      dispatch(setAnalogsList([]))
      return
    }
    setAnalogsLoading(true)
    getApi()
      .casId
      .get(casId)
      .then(({ partner_products }) => {
        const analogPartnerProducts = partner_products
          .filter((item: ProductType) => item.id !== id)
        dispatch(setAnalogsList(analogPartnerProducts))
        setTotalAnalogsPagination(analogPartnerProducts.length)
      })
      .catch((error: ApiError) => {
        dispatch(setAnalogsList([]))
        notification.error({
          message: 'Ошибка при получении CasID',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setAnalogsLoading(false)
      })
  }

  const searchPaginationHandler = (paginationValue: number) => {
    onSearchHandler(undefined, paginationValue)
  }

  const onSelectHandler = (value: string) => {
    setVendor(value)
  }

  const setObjectToLocalStorage = (object: ProductType) => {
    const data = localStorage.getItem('basketAppscience')
    // eslint-disable-next-line
    if (!data) {
      const dataArray = []
      dataArray.push({ ...object, count: 1 })
      localStorage.setItem('basketAppscience', JSON.stringify(dataArray))
      dispatch(setProductsListAction([...dataArray]));
      (object.availability_type === 'ON-DEMAND') && availabilityRequestHandler(object)
    }
    else {
      const dataArray: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)
      let alreadyObjectInLocal = false
      dataArray.forEach((item, index) => {
        if (object.id === item.id) {
          // eslint-disable-next-line
          dataArray[index].count = dataArray[index].count + 1
          alreadyObjectInLocal = true
        }
      })
      if (alreadyObjectInLocal) {
        notificationCount(`+1 товар ${object.name}`)
      }
      else {
        dataArray.push({ ...object, count: 1 })
        notificationSuccess(`${object.name} добавлен в корзину`)
      }
      localStorage.setItem('basketAppscience', JSON.stringify(dataArray))
      dispatch(setProductsListAction([...dataArray]));
      (object.availability_type === 'ON-DEMAND') && availabilityRequestHandler(object)
    }
  }

  const availabilityRequestHandler = (object: ProductType) => {
    dispatch(setAddToBtnDisable(true))
    const id = object.id
    const _dataArray: ProductBasketType[] = JSON.parse(localStorage.getItem('basketAppscience')!)
    const count = _dataArray.filter(item => item.id === id)[0].count

    availabilityApi
      .getProductAvailability(id, count)
      .then(({ available, excel_comment }: GetProductAvailabilityResponse) => {
        _dataArray.forEach((_item, index) => {
          if (_item.id === id) {
            _dataArray[index].availability_flag = available
            _dataArray[index].excel_comment = excel_comment
          }
        })
        localStorage.setItem('basketAppscience', JSON.stringify(_dataArray))
        dispatch(setProductsListAction([..._dataArray]))
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при получении данных о доступности',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setAddToBtnDisable(false))
      })
  }

  const searchResultProducts: Array<ProductType> = useMemo(() => {
    if (!`${filteredWord}`.trim()) {
      return products
    }

    const trimString: string = `${filteredWord}`.trim().toUpperCase()
    return products
      .filter(item => isFilteredWord(item, trimString))
  }, [filteredWord, products])
  const totalSize = useMemo(() => searchResultProducts.length, [searchResultProducts])

  //Sorting Middleware
  const sortingAnalogs = (): Array<ProductType> => {
    const result = new Array(...analogs)
    if (sortingProp && (sortingProp !== 'partnership_type')) {
      return isReverseSorting ?
        //@ts-ignore
        orderBy(result, [item => item[sortingProp as string].toLowerCase()], ['asc']).reverse() : orderBy(result, [item => item[sortingProp as string].toLowerCase()], ['asc'])
    }
    //Блок для сортировки аналогов с PartnershipType и без PartnershipType
    else if (sortingProp === 'partnership_type') {
      const arrayWithPartnershipType = result
        .filter(item => item.partnership_type)
        .sort((a, b) => caseInsensitiveCompare(a.partnership_type, b.partnership_type))
      const arrayWithoutPartnershipType = result.filter(item => !item.partnership_type)
      const calculateArray = [...arrayWithPartnershipType, ...arrayWithoutPartnershipType]
      return isReverseSorting ? calculateArray.reverse() : result
    }
    else {
      return result
    }
  }

  const filteredAnalogsArray = () => {
    const startIndex: number = (paginationAnalogsCurrent - 1) * currentShowAnalogsItems
    const endIndex: number = paginationAnalogsCurrent * currentShowAnalogsItems
    const filteredString = `${filteredWord}`.trim()
    const sortedArray = sortingAnalogs()

    if (filteredString) {
      const trimString: string = filteredString.toUpperCase()
      const _filteredArray = sortedArray.filter(item => isFilteredWord(item, trimString))
      return _filteredArray.slice(startIndex, endIndex)
    }
    else {
      return sortedArray.slice(startIndex, endIndex)
    }
  }

  const addToBasketHandler = (item: ProductType) => {
    setObjectToLocalStorage(item)
  }

  const onChooseSearchLabelHandler = (id: string) => {
    setDropdownVisible(false)
    setRequestSearchWord(id)
    onSearchHandler(id)
  }

  const addToBasketFromSearchResult = (
    e: React.MouseEvent<HTMLElement>,
    product: ProductType,
    basketProducts: ProductBasketType[],
  ) => {
    e.preventDefault()
    e.stopPropagation()

    const { id } = product
    const index = basketProducts.findIndex(item => item.id === id)

    if (index === -1) {
      dispatch(setAddToBtnDisable(true))

      getProductById
        .get(id)
        .then(response => {
          const { partner_products } = response
          addToBasketHandler(partner_products[0])
        })
        .catch((error: ApiError) => {
          notification.error({
            message: 'Ошибка при получении данных о товаре',
            description: error.getRuText?.() || error.message,
            duration: 5,
          })
          Sentry.captureException(error)
        })
        .finally(() => {
          dispatch(setAddToBtnDisable(false))
        })
    }
    else {
      addToBasketHandler(product)
    }
  }

  const searchResult = (suggestions: Array<Suggestion>) => {
    setDropdownVisible(true)

    if (!suggestions.length) {
      return [{
        value: '',
        label: <div>Нет результатов...</div>,
      }]
    }

    return suggestions
      .map((suggestion, index) => ({
        value: suggestion.data.id,
        label:
          <SearchLabel
            theme={theme}
            index={index}
            suggestions={suggestions}
            addToBasketFromSearchResult={addToBasketFromSearchResult}
          />,
      }))
  }

  const suggestRequestHandler = (value: string) => {
    axiosSuggestRequestData.source.cancel()

    if (!value.trim()) {
      setSearchOptions(searchResult([]))
      return
    }

    axiosSuggestRequestData.cancelToken = axios.CancelToken
    axiosSuggestRequestData.source = axiosSuggestRequestData.cancelToken.source()

    searchApi
      .getSuggestions(
        {
          vendors: searchVendorsList,
          part: value,
          count: 100,
        },
        axiosSuggestRequestData.source.token,
      )
      .then((response: GetSuggestionsResponse) => {
        const suggestions = response.suggestions
        setSearchOptions(searchResult(suggestions))
      })
  }

  const onSearchInput = (value: string) => {
    setRequestSearchWord(value)
    suggestRequestHandler(value)
  }

  return {
    searchProp,
    searchWord,
    vendor,
    searchLoading,
    filteredWord,
    searched,
    searchPaginationCurrent,
    searchPaginationTotalProductCount,
    products,
    totalSize,
    isDropdownVisible,
    visibleLoader,
    loadingSearchByWord,
    searchOptions,
    requestSearchWord,
    analogs,
    analogsCasId,
    analogsLoading,
    currentShowAnalogsItems,
    paginationAnalogsCurrent,
    filteredAnalogsArray,
    totalAnalogsPagination,
    vendorsList,
    searchVendorsList,
    sortingAnalogs,
    isReverseSorting,
    sortingProp,

    setSearchProp,
    searchPaginationHandler,
    _setSearchWord,
    setVendor,
    setSearchLoading,
    setFilteredWord,
    setSearched,
    setSearchPaginationCurrent,
    setSearchWord,
    onSearchHandler,
    onSelectHandler,
    setObjectToLocalStorage,
    searchResultProducts,
    addToBasketHandler,
    onSearchInput,
    setDropdownVisible,
    setVisibleLoader,
    setLoadingSearchByWord,
    onChooseSearchLabelHandler,
    setRequestSearchWord,
    setCurrentShowAnalogsItems,
    setPaginatioAnalogsCurrent,
    setVendorsList,
    setSearchVendorsList,
    setIsReverseSorting,
    setSortingProp,
  }
}