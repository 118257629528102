import axios from 'axios'
import * as _history from 'history'
import { notification } from 'antd'
import { AUTH_TOKEN } from '../config/AppConfig'
import { ApiError, DEFAULT_CUSTOM_ERROR } from './apiError/apiError'
import { ENV_DEV, ENV_TEST, getEnvironment } from '../constants/environment'

//TODO:improvements.
const history = _history.createBrowserHistory()
const apiCreator = axios.create({
  withCredentials: false,
})

const DEV_MODE_PAYLOAD_KEY = 'x-dev-mode'
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'x-auth-token'
const PUBLIC_REQUEST_KEY = 'public-request'

// API request interceptor
apiCreator.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN)

  if ((getEnvironment() == ENV_DEV || getEnvironment() == ENV_TEST) && config?.headers) {
    config.headers[DEV_MODE_PAYLOAD_KEY] = 'true'
  }

  if (jwtToken && config.headers && !config.headers[PUBLIC_REQUEST_KEY]) {
    config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
  }

  if (!jwtToken && !config.headers?.[PUBLIC_REQUEST_KEY]) {
    history.push(ENTRY_ROUTE)
    window.location.reload()
  }
  return config
}, error => {
  const defaultMessage = 'Interceptor request error'
  notification.error({
    message: defaultMessage,
  })

  return Promise.reject(error)
})

// API response interceptor
apiCreator.interceptors.response.use(response => {
  if (response.headers[AUTH_TOKEN]) {
    localStorage.setItem(AUTH_TOKEN, response.headers[AUTH_TOKEN])
  }

  return response
}, error => {
  const _location = window.location

  // Remove token and redirect
  if (error?.response?.status === 403) {
    localStorage.removeItem(AUTH_TOKEN)
    const redirect = `${_location.pathname}${_location.search}`
    history.push(`${ENTRY_ROUTE}?redirect=${redirect}`)
    window.location.reload()
  }

  return Promise.reject(new ApiError(error?.response?.data ?? DEFAULT_CUSTOM_ERROR))
})

export default apiCreator
