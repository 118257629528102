import { API_URL } from '../../constants/environment'
import axiosInstance from '../apiCreator'

export type AttachRequest = {
  id: string;
  proposal_doc_url: string;
  proposal_pdf_url: string;
  spreadsheet_url: string;
  proposal_spreadsheet_url: string;
  proposal_spreadsheet_rub_url: string;
}

export type AttachResponse = Record<string, never>;

type AttachServiceType = {
  attach: (args: {
    id: string;
    proposalDocUrl: string;
    proposalPdfUrl: string;
    spreadsheetUrl: string;
    proposalSpreadsheetUrl: string;
    proposalSpreadsheetRubUrl: string;
  }) => Promise<AttachResponse>;
}

const AttachService: AttachServiceType = {
  attach: ({ id, proposalDocUrl, proposalPdfUrl, spreadsheetUrl, proposalSpreadsheetUrl, proposalSpreadsheetRubUrl }) =>
    axiosInstance
      .post<never, AttachResponse, AttachRequest>(
        `${API_URL.PROPOSALS}/attach`,
        {
          id,
          proposal_doc_url: proposalDocUrl,
          proposal_pdf_url: proposalPdfUrl,
          spreadsheet_url: spreadsheetUrl,
          proposal_spreadsheet_url: proposalSpreadsheetUrl,
          proposal_spreadsheet_rub_url: proposalSpreadsheetRubUrl,
        },
      ),
}

export default AttachService
