import styles from './PaymentsView.module.css'

export function PaymentsView() {
  return (
    <iframe
      src='https://analytics.appscience.pro/public/dashboard/697c4625-74fb-4d1b-babc-d3469a4e1f16'
      frameBorder='0'
      width='100%'
      height='100%'
      className={styles['iframe']}
    ></iframe>
  )
}
