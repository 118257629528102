import { ActionIcon, Badge, Burger, Drawer } from '@mantine/core'
import { useCallback, useMemo, useState } from 'react'
import { ThemeConfigurator } from '../../../../components/configurator'
import { CogIcon } from '@heroicons/react/outline'
import styles from './DefaultHeader.module.css'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../../../store/theme/selectors'
import { ThemeColorEnum } from '../../../../views/OrdersView/OrderPositions.utils'
import cn from 'classnames'
import { ENV_PROD, getEnvironment } from '../../../../constants/environment'

type DefaultHeaderProps = {
  headerTitle: string;
  isNavOpened: boolean;
  onNavToggle: () => void;
}

export function DefaultHeader({ headerTitle, isNavOpened, onNavToggle }: DefaultHeaderProps) {
  const environment: string = useMemo(
    () => getEnvironment(),
    [],
  )
  const [isSettingsOpened, setSettingsOpened] = useState(false)
  const theme: ThemeColorEnum = useSelector(selectTheme)
  const isDarkTheme = theme === ThemeColorEnum.Dark

  const onBurgerClick = useCallback(() => {
    onNavToggle()
  }, [onNavToggle])

  return (
    <div
      className={cn(
        styles['root'],
        { [styles['root--dark-theme']]: isDarkTheme },
      )}
    >
      <div className='flex'>
        <Burger
          opened={isNavOpened}
          onClick={onBurgerClick}
          size='md'
          className={styles['burger']}
          color={isDarkTheme ? '#C1C2C5' : '#495057'}
        />
        {headerTitle && (
          <h2 className={styles['header-title']}>
            {headerTitle}
          </h2>
        )}
      </div>
      <div className={styles['right-section']}>
        {environment != ENV_PROD && (
          <Badge
            variant='filled'
            size='lg'
          >
            {environment} env
          </Badge>
        )}
        <ActionIcon
          size='lg'
          onClick={() => setSettingsOpened(true)}
          className={styles['settings-icon']}
        >
          <CogIcon
            className='w-7 h-7'
          />
        </ActionIcon>
        <Drawer
          opened={isSettingsOpened}
          onClose={() => setSettingsOpened(false)}
          title='Settings'
          size='md'
          position='right'
          padding='md'
        >
          <div className={styles['settings-container']}>
            <ThemeConfigurator />
          </div>
        </Drawer>
      </div>
    </div>
  )
}
