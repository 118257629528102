import React, {
  FormEvent,
  useEffect,
  useState,
} from 'react'
import { getApi } from '../../../../../api/getApi'
import { HandleInputProductType, ShippingConditionEnum } from '../../../../../store/search/reducer'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  notification,
} from 'antd'
import * as Sentry from '@sentry/react'
import {ApiError} from '../../../../../api/apiError/apiError'

const ALL_SHIPPING_CONDITIONS: Array<ShippingConditionEnum> = [
  ShippingConditionEnum.Room,
  ShippingConditionEnum.DryIce,
  ShippingConditionEnum.Refr,
  ShippingConditionEnum.Freezer,
]

const { Option } = Select

type HandleInputFormType = {
  initialValues: HandleInputProductType;
  showFinishBtn?: boolean;
  onFinishHandler?: (object: HandleInputProductType) => void;
  onChangeHandler?: (name: string, value: string) => void;
}

export const HandleInputForm: React.FC<HandleInputFormType> = ({
  onFinishHandler,
  onChangeHandler,
  showFinishBtn,
  initialValues,
}) => {
  const {
    name,
    catalogue_id,
    cas_id,
    price,
    shipping_conditions,
    package_size,
    vendor,
    partner_url,
    count,
    info_from_client,
  } = initialValues

  const [form] = Form.useForm()
  const [price_currency, setCurrencyPrice] = useState<string>('USD')
  const [vendorsList, setVendorsList] = useState<string[]>([])

  useEffect(() => {
    let isMounted = true

    getApi()
      .vendors
      .getForHandleInput()
      .then(vendorsString => {
        if (isMounted) {
          setVendorsList(vendorsString.split('\n'))
        }
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при получении списка вендоров',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })

    return () => {
      isMounted = false
    }
  }, [])

  const onFinish = (product: HandleInputProductType) => {
    if (onFinishHandler) {
      onFinishHandler({
        ...product,
        input_by_handle: true,
      })
    }
    form.resetFields()
  }

  const onChange = (event: FormEvent<HTMLFormElement>) => {
    const inputElement = event.nativeEvent.target as HTMLInputElement
    if (onChangeHandler) {
      onChangeHandler(inputElement.id, inputElement.value)
    }
  }

  const onSelect = (nameValue: string, value: string) => {
    if (onChangeHandler) {
      onChangeHandler(nameValue, value)
    }
  }

  const priceAfter = (
    <Select
      defaultValue={price_currency}
      style={{ width: 70 }}
      onSelect={(value: string) => onSelect('price_currency', value)}
      onChange={value => setCurrencyPrice(value)}
    >
      <Option value='USD'>USD</Option>
      <Option value='EUR'>EUR</Option>
      <Option value='GBP'>GBP</Option>
      <Option value='CHF'>CHF</Option>
      <Option value='RUB'>RUB</Option>
    </Select>
  )

  return (
    <Card
      bordered
    >
      <div className='handle-input-basket-section'>
        <Form
          form={form}
          onChange={onChange}
          onFinish={onFinish}
          initialValues={{
            name,
            catalogue_id,
            cas_id,
            price,
            price_currency,
            shipping_conditions,
            package_size,
            vendor,
            partner_url,
            count,
            info_from_client,
          }}
        >
          <Row>
            <Col
              span={24}
              className='handle-input-basket-input'
            >
              <Form.Item
                label='Название позиции:'
                name='name'
                rules={[{
                  message: 'Введите название',
                }]}
              >
                <Input
                  placeholder='Введите название'
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            className='form-item-line'
          >
            <Row
              gutter={20}
            >
              <Col
                span={24}
                md={8}
                lg={8}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label='Catalogue id'
                  name='catalogue_id'
                  rules={[{
                    message: 'Введите id',
                  }]}
                >
                  <Input
                    placeholder='Введите id'
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={8}
                lg={8}
                className='handle-input-basket-input'
              >
                <Form.Item
                  label='CAS'
                  name='cas_id'
                  rules={[{
                    message: 'Введите cas',
                  }]}
                >
                  <Input
                    placeholder='Введите cas'
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={8}
                lg={8}
                className='handle-input-basket-input'
              >
                <Form.Item
                  label='Цена'
                  name='price'
                  rules={[{
                    message: 'Введите цену',
                  }]}
                >
                  <Input
                    placeholder='Введите цену'
                    type='number'
                    addonAfter={priceAfter}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row
              gutter={20}
            >
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label='Темп. режим'
                  name='shipping_conditions'
                  rules={[{
                    message: 'Введите температуру',
                  }]}
                >
                  <Select<ShippingConditionEnum>
                    allowClear
                    onSelect={(value: string) => onSelect('shipping_conditions', value)}
                  >
                    {ALL_SHIPPING_CONDITIONS.map((shippingCondition, index) => (
                      <Option
                        key={index}
                        value={shippingCondition}
                      >
                        {shippingCondition}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label='Фасовка:'
                  name='package_size'
                  rules={[{
                    message: 'Введите фасовку',
                  }]}
                >
                  <Input
                    placeholder='Введите фасовку'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row
              gutter={20}
            >
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label='Вендор:'
                  name='vendor'
                  rules={[{
                    message: 'Введите вендор',
                  }]}
                >
                  <Select
                    showSearch
                    allowClear
                    onSelect={(value: string) => onSelect('vendor', value)}
                  >
                    {vendorsList.map((item, key) => (
                      <Option
                        key={key}
                        value={item}
                      >
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label='Ссылка:'
                  name='partner_url'
                  rules={[{
                    message: 'Введите ссылку',
                  }]}
                >
                  <Input
                    placeholder='Введите ссылку'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row>
              <Col
                span={24}
                className='handle-input-basket-input'
              >
                <Form.Item
                  label='Информация от клиента:'
                  name='info_from_client'
                >
                  <Input.TextArea
                    placeholder='Введите информацию'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row
              gutter={30}
            >
              <Col
                span={24}
                md={8}
                className='handle-input-basket-input'
              >
                <Form.Item
                  label='Количество'
                  name='count'
                  rules={[{
                    message: 'Введите количество',
                  }]}
                >
                  <Input
                    type='number'
                    min={1}
                  />
                </Form.Item>
              </Col>
              {showFinishBtn && (
                <Col
                  span={24}
                  md={5}
                  className='d-flex justify-content-end justify-content-md-start'
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                  >
                    Добавить позицию в корзину
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Form>
      </div>
    </Card>
  )
}
