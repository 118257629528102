import React from 'react'
import {Drawer} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {
  selectBasketProducts,
  selectEditedItem,
  selectOpenHandleEditor,
} from '../../../../store/basket/selectors'
import {HandleInputForm} from './handle-input-form/index'
import {
  setOpenHandleEditor,
  setProductsListAction,
} from '../../../../store/basket/reducer'

export const SideHandleEditorContent: React.FC = () => {

  //Drawwer Visible
  const dispatch = useDispatch()
  const editedItemIndex = useSelector(selectEditedItem)
  const basketProducts = useSelector(selectBasketProducts)
  const openHandleEditor = useSelector(selectOpenHandleEditor)
  const editedItem = editedItemIndex ? basketProducts[editedItemIndex] : basketProducts[0]
  const onClose = () => {
    dispatch(setOpenHandleEditor(false))
  }

  const onChangeHandler = (name: string, value: string) => {
    const item = {...basketProducts[editedItemIndex!]}

    //@ts-ignore
    item[name] = value
    const resultArray = [...basketProducts]
    resultArray[editedItemIndex!] = {...item}
    dispatch(setProductsListAction(resultArray))
    localStorage.setItem('basketAppscience', JSON.stringify(resultArray))
  }

  return (
    <>
      <Drawer
        title='Редактирование позиции'
        placement='right'
        className='side-handle-editor-content'
        destroyOnClose={true}
        onClose={onClose} visible={openHandleEditor}>
        <div className='side-handle-editor-content'>
          <HandleInputForm
            //@ts-ignore
            initialValues={editedItem}
            onChangeHandler={onChangeHandler}
            showFinishBtn={false}/>
        </div>
      </Drawer>
    </>
  )
}
