import {createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'

const initialState = {
  googleSheetsLink: '',
  rubGoogleSheetsLink: '',
  pdfLink: '',
  docLink: '',
  billPdfLink: '',
  importTableLink: '',
  specificationPdfLink: '',
  specificationDocLink: '',
  linkToTable: '',
  orderValue: '',
  disabledOrderForm: false,
  importOrGenerateLoading: false,
}

const generateSlice = createSlice({
  name: 'generate',
  initialState: {...initialState},
  reducers: {
    setGoogleSheetsLinkAction(state, action: ActionType<string>) {
      state.googleSheetsLink = action.payload
    },
    setRubGoogleSheetsLinkAction(state, action: ActionType<string>) {
      state.rubGoogleSheetsLink = action.payload
    },
    setPdfLinkAction(state, action: ActionType<string>) {
      state.pdfLink = action.payload
    },
    setDocLinkAction(state, action: ActionType<string>) {
      state.docLink = action.payload
    },
    setBillPdfLinkAction(state, action: ActionType<string>) {
      state.billPdfLink = action.payload
    },
    setImportTablePdfLinkAction(state, action: ActionType<string>) {
      state.importTableLink = action.payload
    },
    setSpecificationPdfLinkAction(state, action: ActionType<string>) {
      state.specificationPdfLink = action.payload
    },
    setSpecificationDocLinkAction(state, action: ActionType<string>) {
      state.specificationDocLink = action.payload
    },
    setLinkToTableAction(state, action: ActionType<string>) {
      state.linkToTable = action.payload
    },
    setOrderValueAction(state, action: ActionType<string>) {
      state.orderValue = action.payload
    },
    setDisabledOrderFormAction(state, action: ActionType<boolean>) {
      state.disabledOrderForm = action.payload
    },
    setImportOrGenerateLoading(state, action: ActionType<boolean>) {
      state.importOrGenerateLoading = action.payload
    },
  },
})

export const generateReducer = generateSlice.reducer
export const {
  setGoogleSheetsLinkAction,
  setRubGoogleSheetsLinkAction,
  setPdfLinkAction,
  setDocLinkAction,
  setBillPdfLinkAction,
  setImportTablePdfLinkAction,
  setSpecificationPdfLinkAction,
  setSpecificationDocLinkAction,
  setOrderValueAction,
  setLinkToTableAction,
  setDisabledOrderFormAction,
  setImportOrGenerateLoading,
} = generateSlice.actions
