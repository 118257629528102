export function getEnvironment(): string {
  switch (window.location.hostname) {
    case PROD_HOSTNAME:
      return ENV_PROD
    case STAGE_HOSTNAME:
      return ENV_STAGE
    case STAGE_1_HOSTNAME:
      return ENV_STAGE_1
    case STAGE_2_HOSTNAME:
      return ENV_STAGE_2
    case STAGE_3_HOSTNAME:
      return ENV_STAGE_3
    case TEST_HOSTNAME:
      return ENV_TEST
    case TEST_1_HOSTNAME:
      return ENV_TEST_1
    case TEST_2_HOSTNAME:
      return ENV_TEST_2
    case TEST_3_HOSTNAME:
      return ENV_TEST_3
  }
  return ENV_DEV
}

export const ENV_PROD = 'prod'
export const ENV_DEV = 'dev'
export const ENV_STAGE = 'stage'
export const ENV_STAGE_1 = 'stage1'
export const ENV_STAGE_2 = 'stage2'
export const ENV_STAGE_3 = 'stage3'
export const ENV_TEST = 'test'
export const ENV_TEST_1 = 'test1'
export const ENV_TEST_2 = 'test2'
export const ENV_TEST_3 = 'test3'

export const PROD_HOSTNAME = 'order.appscience.pro'

export const STAGE_HOSTNAME = 'stage.order.appscience.pro'
export const STAGE_1_HOSTNAME = 'stage1.order.appscience.pro'
export const STAGE_2_HOSTNAME = 'stage2.order.appscience.pro'
export const STAGE_3_HOSTNAME = 'stage3.order.appscience.pro'

export const TEST_HOSTNAME = 'test.order.appscience.pro'
export const TEST_1_HOSTNAME = 'test1.order.appscience.pro'
export const TEST_2_HOSTNAME = 'test2.order.appscience.pro'
export const TEST_3_HOSTNAME = 'test3.order.appscience.pro'

export const PROD_API_URL = 'https://api.appscience.pro'
export const DEV_API_URL = 'https://common-dev.api.appscience.pro'

export const STAGE_API_URL = 'https://stage.api.appscience.pro'
export const STAGE_1_API_URL = 'https://stage1.api.appscience.pro'
export const STAGE_2_API_URL = 'https://stage2.api.appscience.pro'
export const STAGE_3_API_URL = 'https://stage3.api.appscience.pro'

export const TEST_API_URL = 'https://test.api.appscience.pro'
export const TEST_1_API_URL = 'https://test1.api.appscience.pro'
export const TEST_2_API_URL = 'https://test2.api.appscience.pro'
export const TEST_3_API_URL = 'https://test3.api.appscience.pro'

export const CURRENT_API_URL = (():string => {
  switch (getEnvironment()) {
    case ENV_PROD:
      return PROD_API_URL

    case ENV_STAGE:
      return STAGE_API_URL
    case ENV_STAGE_1:
      return STAGE_1_API_URL
    case ENV_STAGE_2:
      return STAGE_2_API_URL
    case ENV_STAGE_3:
      return STAGE_3_API_URL

    case ENV_TEST:
      return TEST_API_URL
    case ENV_TEST_1:
      return TEST_1_API_URL
    case ENV_TEST_2:
      return TEST_2_API_URL
    case ENV_TEST_3:
      return TEST_3_API_URL
  }
  return DEV_API_URL
})()

export const API_URL = {
  AVAILABILITY: process.env.REACT_APP_AVAILABILITY_URL || CURRENT_API_URL + '/availability',
  AUTH: process.env.REACT_APP_AUTH_URL || CURRENT_API_URL + '/auth',
  CURRENCIES: process.env.REACT_APP_CURRENCIES_URL || CURRENT_API_URL + '/currencies',
  CUSTOMER_AREA: process.env.REACT_APP_CUSTOMER_AREA_URL || CURRENT_API_URL + '/customer-area',
  EXCEL_CONVERTER: process.env.REACT_APP_EXCEL_CONVERTER_URL || CURRENT_API_URL + '/excel-converter',
  METABASE_EMBEDDING: process.env.REACT_APP_METABASE_EMBEDDING_URL || CURRENT_API_URL + '/metabase_embedding',
  PARTNER_PRODUCTS: process.env.REACT_APP_PARTNER_PRODUCTS_URL || CURRENT_API_URL + '/partner-products',
  PROPOSALS: process.env.REACT_APP_PROPOSALS_URL || CURRENT_API_URL + '/proposals',
  SUGGEST: process.env.REACT_APP_SUGGEST_URL || CURRENT_API_URL + '/suggest',
  TWIN: process.env.REACT_APP_TWIN_URL || CURRENT_API_URL + '/twin',
}
