import { Image } from 'antd'
import openLinkIconSrc from '../../../../../../assets/svg/open-link-icon.svg'

type ProposalActionIconProps = {
  src: string;
  isGray?: boolean;
}

export function ProposalActionIcon({ src, isGray = false }: ProposalActionIconProps) {
  return (
    <Image
      width={17}
      height={17}
      src={src}
      style={{
        minWidth: 17,
        ...(isGray && { filter: 'opacity(45%)' }),
      }}
    />
  )
}

export function OpenLinkIcon() {
  return (
    <ProposalActionIcon
      src={openLinkIconSrc}
      isGray
    />
  )
}
