import { Button, Card, Col, Input, Row } from 'antd'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useLocation} from 'react-router-dom'
import { setOrderValueAction } from '../../../../../../store/generate-table/reducer'
import { selectDisabledOrderForm, selectLinkToTable, selectOrderValue } from '../../../../../../store/generate-table/selectors'
import { TableGenerationModal } from '../TableGenerationModal/TableGenerationModal'
import {TableImportModal} from '../TableImportModal/TableImportModal'

type TableGenerationProps = {
  isButtonDisabled: boolean;
  isLoading: boolean;
  generateSpreadsheet: (isWithSavingData: boolean) => void;
  importTable: (parentDealId: number) => void;
}

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function TableGeneration({
  isButtonDisabled,
  isLoading,
  generateSpreadsheet,
  importTable,
}: TableGenerationProps) {
  const dispatch = useDispatch()
  const query = useQuery()
  const urlNumber = query.get('number') ? query.get('number') : ''
  const orderValue = useSelector(selectOrderValue)
  const isOrderDisabled = useSelector(selectDisabledOrderForm)
  const linkToTable = useSelector(selectLinkToTable)
  const [isTableGenerationModalVisible, setTableGenerationModalVisibility] = useState<boolean>(false)
  const [isTableImportModalVisible, setTableImportModalVisibility] = useState<boolean>(false)

  const setOrderValue = useCallback((value: string) => {
    const newOrderValue: string =
      Array.from(value.trim())
        .filter(item => Number.isInteger(parseInt(item, 10)))
        .join('')

    dispatch(setOrderValueAction(newOrderValue))
  }, [dispatch])

  const confirmSpreadsheetGeneration = useCallback(() => {
    setTableGenerationModalVisibility(true)
  }, [])

  const confirmImportTable = useCallback(() => {
    setTableImportModalVisibility(true)
  }, [])

  const onGenerateSpreadsheetClick = useCallback(() => {
    if (linkToTable) {
      confirmSpreadsheetGeneration()
      return
    }

    generateSpreadsheet(false)
  }, [linkToTable, confirmSpreadsheetGeneration, generateSpreadsheet])

  return (
    <Card>
      <Row>
        <Col
          span={10}
          className='pr-2'
        >
          <Input
            addonBefore={<span>Номер сделки</span>}
            placeholder='Введите номер'
            disabled={isOrderDisabled}
            value={orderValue}
            onChange={e => setOrderValue(e.target.value)}
          />
        </Col>
        <Col
          span={8}
          className='pl-2'
        >
          <Button
            type='primary'
            disabled={isButtonDisabled}
            block
            onClick={onGenerateSpreadsheetClick}
          >
            Сгенерировать таблицу
          </Button>
        </Col>
        <Col
          span={6}
          className='pl-2'
        >
          <Button
            block
            disabled={isButtonDisabled}
          >
            <a
              href={linkToTable}
              target='_blank'
            >
              Открыть таблицу
            </a>
          </Button>
        </Col>
      </Row>
      <Row className='mt-[20px]'>
        <Col
          span={10}
          className='pr-2'
        />
        <Col
          span={8}
          className='pl-2'
        >
          <Button
            type='primary'
            disabled={isButtonDisabled || !urlNumber}
            block
            onClick={confirmImportTable}
          >
            Импортировать таблицу
          </Button>
        </Col>
      </Row>
      <TableGenerationModal
        isVisible={isTableGenerationModalVisible}
        onClose={() => setTableGenerationModalVisibility(false)}
        generateSpreadsheet={generateSpreadsheet}
      />
      <TableImportModal
        isVisible={isTableImportModalVisible}
        onClose={() => setTableImportModalVisibility(false)}
        importTable={importTable}
      />
    </Card>
  )
}
